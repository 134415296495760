<template>
  <v-row justify-md="center" align="stretch">
    <v-col md="4">
      <VerifyCard/>
    </v-col>
  </v-row>
</template>

<script>
import VerifyCard from '@/components/Authorisation/VerifyCard.vue';

export default {
  name: 'Verify',
  components: {
    VerifyCard,
  },
  created() {
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setTitle', 'Account verifiëren');
    this.$hero.$emit('setBreadcrumbs', []);
  },
};
</script>

<style scoped>

</style>
